import React from 'react'
const CoupangAd = () => (
  <div style={{ marginTop: '50px' }}>
    {/*<iframe*/}
    {/*  src="https://coupa.ng/cdln7A"*/}
    {/*  width="100%"*/}
    {/*  height="75"*/}
    {/*  frameBorder="0"*/}
    {/*  scrolling="no"*/}
    {/*  referrerPolicy="unsafe-url"*/}
    {/*></iframe>*/}
    <h4 style={{ margin: 0, padding: 0 }}>
      ※ 이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를
      제공받습니다.
    </h4>
    <a
      href="https://link.coupang.com/a/bhkFYy"
      target="_blank"
      referrerPolicy="unsafe-url"
    >
      <img
        src="https://image12.coupangcdn.com/image/affiliate/banner/36d1c365e1548e3e4db9cae5ad621164@2x.jpg"
        alt="유리아쥬 제모스 스틱 레브르 립밤 4g x 10개, 12개, 무향"
        width="120"
        height="240"
      />
    </a>
    <a
      href="https://link.coupang.com/a/MFpAt"
      target="_blank"
      referrerPolicy="unsafe-url"
    >
      <img
        src="https://static.coupangcdn.com/image/affiliate/banner/98234a3c03c59986c432d13d05c42996@2x.jpg"
        alt="솔가 어드밴스드 칼슘 컴플렉스 타블렛, 120개입, 1개"
        width="120"
        height="240"
      />
    </a>
    <a
      href="https://link.coupang.com/a/Ewo1L"
      target="_blank"
      referrerPolicy="unsafe-url"
    >
      <img
        src="https://image3.coupangcdn.com/image/affiliate/banner/3f18e92efb1d6982620d4dbd0e3a0db6@2x.jpg"
        alt="커세어 코리아 정품 DARK CORE PRO 무선 충전 RGB 게이밍 마우스 / 다용도 에코백 사은품 증정, 혼합색상, RGP0076"
        width="120"
        height="240"
      />
    </a>
    <iframe
      src="https://ads-partners.coupang.com/widgets.html?id=625249&template=carousel&trackingCode=AF9179074&subId=&width=720&height=240"
      width="600"
      height="240"
      frameBorder="0"
      scrolling="no"
      referrerPolicy="unsafe-url"
    ></iframe>
    <iframe
      src="https://coupa.ng/cdln76"
      width="100%"
      height="36"
      frameBorder="0"
      scrolling="no"
      referrerPolicy="unsafe-url"
    ></iframe>
  </div>
)

export default CoupangAd
