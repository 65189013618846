/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import favicon from '../../static/favicon.ico'

const SEO = ({
  postPath,
  title,
  searchTexts,
  description,
  category,
  subCategory,
  tags,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            lang
            locale
            title
            description
            siteUrl
          }
        }
      }
    `,
  )
  const keywords = []
  keywords.push(category)
  keywords.push(subCategory)
  keywords.push(tags)

  return (
    // post: title, titleEn, url, description, keywords
    // site: lang, locale, site_name, site_url
    <Helmet
      htmlAttributes={{ lang: site.siteMetadata.lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          property: `og:type`,
          content: `article`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: searchTexts.join('. '),
        },
        {
          name: `description`,
          content: description,
        },
        {
          property: `keywords`,
          content: keywords,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl + postPath,
        },
        // {
        //   property: `og:site_name`,
        //   content: site.siteMetadata.title,
        // },
        // {
        //   property: `og:locale`,
        //   content: site.siteMetadata.locale,
        // },
      ]}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
