import React from 'react'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import AdSenseMultiplex from '../components/AdSense/AdSenseMultiplex'
import TableOfContents from '../components/TableOfContents'
import CoupangAd from '../components/Coupang/CoupangAd'
import AdSenseContentAutoInjection from '../components/AdSense/AdSenseContentAutoInjection'

class PostTemplate extends React.Component {
  render() {
    const data = this.props.data.markdownRemark
    const post = data.frontmatter
    const tableOfContents = data.tableOfContents
    // console.dir(data)
    // const {prev, next, currentPage, numPages} = this.props.pageContext
    return (
      <Layout>
        <SEO
          postPath={data.fields.slug}
          title={post.title}
          searchTexts={post.searchTexts}
          description={data.excerpt}
          category={post.category}
          subCategory={post.subCategory}
          tags={post.tags}
        />
        <article className="article-page">
          <div className="page-content">
            <div className="wrap-content">
              <div className="page-top-ads">
                <AdSenseContentAutoInjection />
              </div>
              <header className="header-page">
                <h1 className="page-title">{post.title}</h1>
                <div className="page-date">
                  <span>
                    created {post.created} | updated {post.updated}
                  </span>
                </div>
                <div className="page-tag">
                  {post.tags &&
                    post.tags.map((tag) => (
                      <span key={tag}>
                        <Link className="tag" to={`/tags/${kebabCase(tag)}/`}>
                          # {tag}
                        </Link>
                      </span>
                    ))}
                </div>
              </header>

              {/*// https://soopdop.github.io/2020/12/03/add-table-of-content-gatsby/*/}
              {/*<TableOfContents content={tableOfContents} />*/}
              <div dangerouslySetInnerHTML={{ __html: data.html }} />

              <footer className="page-footer">
                <div className="page-bottom-ads">
                  <AdSenseMultiplex />
                  <CoupangAd />
                </div>
              </footer>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default PostTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      fields {
        slug
      }
      frontmatter {
        title
        searchTexts
        category
        subCategory
        tags
        created(formatString: "MMM DD, YYYY")
        updated(formatString: "MMM DD, YYYY")
      }
      tableOfContents
    }
  }
`
