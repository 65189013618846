import AdSense from 'react-adsense'
import React from 'react'

/*
<ins class="adsbygoogle"
     style="display:block; text-align:center;"
     data-ad-layout="in-article"
     data-ad-format="fluid"
     data-ad-client="ca-pub-2695370852236805"
     data-ad-slot="1713040603"></ins>
*/
const AdSenseContentAutoInjection = () => (
  <AdSense.Google
    client="ca-pub-2695370852236805"
    slot="1713040603"
    className="adsbygoogle"
    style={{ display: 'block', textAlign: 'center' }} // NOTE height 설정 무의미함. 설정해도 광고쪽 설정으로 변경됨
    format="fluid"
    layout="in-article"
  />
)

export default AdSenseContentAutoInjection
